<template>
  <div class="auth-wrapper auth-v1 px-2">  
	    <div class="py-2 ">
		 <b-img
				v-if="UI=='bx'"	
				class="m-2"
				center
				:src="require('@/assets/images/logo/BOXEA-LOGO.png')"
			/>
			<b-img
				v-if="UI=='zx'"	
				class="m-2"
				center
				:src="require('@/assets/images/logo/IZIBOX-LOGO.png')"
			/>
			<b-img
				v-if="UI=='dm'"	
				class="m-2"
				center
				:src="require('@/assets/images/logo/DOMICIALIZ-LOGO.png')"
			/>
			<b-card 
				v-if="etapeencour == 1"	
			>
				<!-- <div class="text-center">
					<div class="h3 font-weight-bolder text-primary my-md-0 ">
					BIENVENUE
					</div>
					<div class="h4 font-weight-normal text-uppercase my-md-0 ">
					chez {{libsoc}}
					</div>
				</div> 
				<br>
				<hr>-->
				<div class="h1 font-weight-bolder text-uppercase text-center text-secondary">
					TAILLE DE MON BOX
				</div>
				<br>
				<div>
					<b-card-group deck>
						<b-card 
							v-for="(tailleitem, UI) in taille" :key="UI"
							border-variant="primary"
							class="text-center"
							:title="tailleitem.memo2"
							:footer="tailleitem.memo"
						>
							<b-button 
								v-on:click="selecttaille(tailleitem)"
								variant="primary"
								pill
								>
								<strong> {{tailleitem.libelle}} </strong>
							</b-button>
						</b-card>
					</b-card-group>
				</div>
			</b-card>
			<div
				v-if="etapeencour == 2"	
			>
				<b-card>
					<div class="text-center">
						<div class="h1 font-weight-bolder text-primary my-md-0 ">
						Votre taille de box
						</div>
						<div class="h4 font-weight-normal text-uppercase my-md-0 ">
						 <strong>{{libtaille}}</strong>
						</div>
						<div class="h4 font-weight-normal my-md-0 ">
						 {{memotaille}}
						</div>
					</div>
				</b-card>
					<div>
  						<b-card-group deck>
							<b-card 
								v-for="(soustailleitem, UI) in soustaille" :key="UI"
								border-variant="primary"
								class="text-center"
							>
								<b-card-body>
									<b-img
										center
										fluid
										:src="soustailleitem.image"
									/>
								</b-card-body>
								<b-card-text >
									<hr>
									{{soustailleitem.text}}
								</b-card-text>
								<b-button 
									v-on:click="selectsoustaille(soustailleitem)"
									variant="primary"
									pill
									>
									Je choisir le <strong>{{soustailleitem.libelle}}</strong> 
								</b-button>
							</b-card>
						</b-card-group>
					</div>
				<b-card>
					<b-row offset-md="4" >
						<b-col class="text-left">
							<b-button
								v-ripple.400="'rgba(186, 191, 199, 0.15)'"
								type="reset"
								variant="outline-primary"
								@click="etapeencour=1"
							>
							  Precedent 
							</b-button>
						</b-col>
						<b-col class="text-right">

						</b-col>
					</b-row>
				</b-card>
			</div>
			<b-card 
				v-if="etapeencour == 3"	
			>
				<div class="text-center">
					<div class="h1 font-weight-bolder text-primary my-md-0 ">
						Votre taille de box
					</div>
					<div class="h4 font-weight-normal text-uppercase my-md-0 ">
					 <strong>{{libsoustaille}}</strong>
					</div>
				</div>
				<hr>
				<b-form @submit.prevent>
					<b-row>
						<b-col cols="12">
						<b-form-checkbox
						  v-model="bsoc"
						  value="1"
						  class="custom-control-primary"
						>
						  PROFESSIONNEL
						</b-form-checkbox>
						</b-col>
						<b-col cols="9">
							<b-form-group
							  v-if="bsoc"
							  label="Raison sociale *"
							  label-for="hi-rs"
							  label-cols-md="3"
							>
							  <b-input-group class="input-group-merge">
								<b-input-group-prepend is-text>
								  <feather-icon icon="HomeIcon" />
								</b-input-group-prepend>
								<b-form-input
									v-model="CRS"
									id="hi-rs"
									placeholder="Raison sociale"
								/>
							  </b-input-group>
							</b-form-group>
						</b-col>
					</b-row>
					<b-row 	v-if="bsoc">
						<b-col cols="6">
							<b-form-group
							  label="SIRET"
							  label-for="hi-rs"
							  label-cols-md="3"
							>
							  <b-input-group class="input-group-merge">
								<b-input-group-prepend is-text>
								</b-input-group-prepend>
								<b-form-input
									v-model="CSIRET"
									id="hi-rs"
									placeholder="N° Siret"
								/>
							  </b-input-group>
							</b-form-group>
						</b-col>
						<b-col cols="6">
							<b-form-group
							  label="N° TVA"
							  label-for="hi-rs"
							  label-cols-md="3"
							>
							  <b-input-group class="input-group-merge">
								<b-input-group-prepend is-text>
								</b-input-group-prepend>
								<b-form-input
									v-model="CTVA"
									id="hi-rs"
									placeholder="N° de TVA intracommunautaire"
								/>
							  </b-input-group>
							</b-form-group>
						</b-col>
					</b-row><br>
					<b-row>
						<b-col cols="6">
							<b-form-group
								label="Civilité"
								label-for="hi-civ"
								 label-cols-md="3"
							>
							  <v-select
								id="hi-civ"
								v-model="selectcivilite"
								label="name"
								placeholder="Choisir une civilité"
								:options="civilite"   
							  />
							</b-form-group>
						</b-col>
					</b-row>
					<b-row>
					  <b-col cols="6">
						<b-form-group
						  label="Nom"
						  label-for="hi-nom"
						  label-cols-md="3"
						>
						  <b-input-group class="input-group-merge">
							<b-input-group-prepend is-text>
							  <feather-icon icon="UserIcon" />
							</b-input-group-prepend>
							<b-form-input
								v-model="CNOM"
								id="hi-nom"
								placeholder="Nom"
							/>
						  </b-input-group>
						</b-form-group>
					  </b-col>
					  <b-col cols="6">
						<b-form-group
							label="Prénom"
							label-for="hi-prenom"
							label-cols-md="3"
						>
						  <b-input-group class="input-group-merge">
							<b-input-group-prepend is-text>
							  <feather-icon icon="UserIcon" />
							</b-input-group-prepend>
							<b-form-input
								v-model="CPRENOM"
								id="hi-prenom"
								placeholder="Prénom"
							/>
						  </b-input-group>
						</b-form-group>
					  </b-col>
					  <b-col cols="6">
						<b-form-group
						  label="Email *"
						  label-for="hi-email"
						  label-cols-md="3"
						>
						  <b-input-group class="input-group-merge">
							<b-input-group-prepend is-text>
							  <feather-icon icon="MailIcon" />
							</b-input-group-prepend>
							<b-form-input
								v-model="CEMAIL"
								id="hi-email"
								type="email"
								placeholder="Email"
							/>
						  </b-input-group>
						</b-form-group>
					  </b-col>
					  <b-col cols="6">
						<b-form-group
						  label="Mobile *"
						  label-for="hi-number"
						  label-cols-md="3"
						>
						  <b-input-group class="input-group-merge">
							<b-input-group-prepend is-text>
							  <feather-icon icon="SmartphoneIcon" />
							</b-input-group-prepend>
							<b-form-input
								v-model="CPORT"
								id="hi-number"
								placeholder="Mobile"
							/>
						  </b-input-group>
						</b-form-group>
					  </b-col>
					</b-row>
					* informations obligatoires (Le numéro de portable est indispensable pour la contractualisation et l'accès à votre Box)
					
				</b-form>
				<hr>
				<b-row offset-md="4" >
					<b-col class="text-left">
						<b-button
							v-ripple.400="'rgba(186, 191, 199, 0.15)'"
							type="reset"
							variant="outline-primary"
							@click="etapeencour=2"
						>
						  Precedent 
						</b-button>
					</b-col>
					<b-col class="text-right">
						<b-button
							v-ripple.400="'rgba(255, 255, 255, 0.15)'"
							type="submit"
							variant="primary"
							class="mr-1"
							@click="goetape4"
						>
						  Voir votre devis
						</b-button>
					</b-col>
				</b-row>
			</b-card>
			<b-card 
				v-if="etapeencour == 4"	
			>
				<div class="text-center">
					<div class="h4 font-weight-normal text-uppercase my-md-0 ">
					 la taille de box <strong>{{libtaille}}</strong>
					</div>
					<div class="h4 font-weight-normal text-uppercase my-md-0 ">
					 le box <strong>{{libsoustaille}}</strong>
					</div>
				</div>
				<hr>
				<div>
					<b-card-group deck>
						<b-card 
							v-for="(soustailleitem2, UI) in soustaille2" :key="UI"
							:bg-variant="soustailleitem2.itype !== 1 ? 'light' : 'white'"
							:border-variant="soustailleitem2.itype === 1 ? 'primary' : 'secondary'"
							:class="soustailleitem2.itype !== 1 ? 'mt-2' : 'mt-0'"
							class="text-center"
						>
							<b-card-text 
								v-if="soustailleitem2.itype === 0"
							>
								Plus petit
							</b-card-text>
							<b-card-text 
								v-if="soustailleitem2.itype === 1"
								class="h4 font-weight-normal text-uppercase my-md-0"
							>
								<strong>Votre choix</strong>
							</b-card-text>
							<b-card-text 
								v-if="soustailleitem2.itype === 2"
							>
								Plus grand
							</b-card-text>
							<b-card-body>
								<b-img
									center
									fluid
									:src="soustailleitem2.image"
								/>
							</b-card-body>
							<b-card-text >
								<hr>
								{{soustailleitem2.text}}
							</b-card-text>
							<b-card-text 
								v-if="soustailleitem2.Resulttarif.length !== 0">
								<hr>
								<div class="h1 font-weight-bolder text-secondary my-md-0 ">
									Choisir vos options :
								</div>
								<br>
								<div 
									 v-for="(tarifitem, UI) in soustailleitem2.Resulttarif" :key="UI"
								>	
									<b-button 
										v-if="tarifitem.nbbox !== 0"
										v-on:click="selecttarif(tarifitem,soustailleitem2)"
										variant="secondary"
										block
										>
										{{tarifitem.libelle}} : {{tarifitem.I_tarifttc}} ({{tarifitem.nbbox}})
									</b-button>
									<b-button 
										v-if="tarifitem.nbbox === 0"
										v-on:click="selecttarif(tarifitem)"
										variant="outline-secondary"
										block
										>
										{{tarifitem.libelle}} : {{tarifitem.I_tarifttc}} ({{tarifitem.nbbox}})
										<div> 
											Plus de disponibilté : Être mis sur liste d'attente 	
										</div>
									</b-button>
									<br>
								</div>	
							</b-card-text>
							<b-card-text 
								v-if="soustailleitem2.Resulttarif.length === 0">
								<hr>
								<strong>Désolé, nous n'avons plus de disponibilité</strong><br><br>
									<b-button 
										v-on:click="gocontact()"
										variant="outline-secondary"
										block
										>
										Être mis sur liste d'attente 
									</b-button>
							</b-card-text>
						</b-card>
					</b-card-group>
				</div>
				<div class="text-center">
					L'Accès Piéton vous permet de garer votre véhicule sur des places à proximité votre Box.<br>
					L'Accès Drive vous permet de garer votre véhicule devant votre Box.
					
				</div>
				<hr>
				<b-row offset-md="4" >
					<b-col class="text-left">
						<b-button
							v-ripple.400="'rgba(186, 191, 199, 0.15)'"
							type="reset"
							variant="outline-secondary"
							@click="etapeencour=3"
						>
						  Precedent 
						</b-button>
					</b-col>
					<b-col class="text-right">

					</b-col>
				</b-row>
			</b-card>
			<b-card>
				<b-progress
					:max="maxetape"
					animated
					variant="secondary"
				>	
				<b-progress-bar :value="etapeencour" class="demo-vertical-spacing" variant="primary">
					  <strong> Etape {{ etapeencour }} sur {{ maxetape }}</strong>
					</b-progress-bar>
				</b-progress>
			</b-card>
		<small class="text-muted">{{ version_resa }}</small>
    </div>
  </div>
</template>
<script>
import { BCard, BButton, BProgress, BProgressBar, BImg, BRow, BCol, BFormInput, BFormCheckbox, BForm, BFormGroup, BInputGroup, BInputGroupPrepend, BCardText, BCardGroup, BCardBody } from 'bootstrap-vue'
import vSelect from 'vue-select'
import { useRouter } from '@core/utils/utils'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
	
export default {
	components: {
		BCard,
		BButton,
		BProgress, 
		BProgressBar, 
		BImg,
		BRow, 
		BCol,
		BFormInput,
		BFormCheckbox, 
		BForm,
		BFormGroup,
		BInputGroup,
		BInputGroupPrepend,
		BCardText,
		BCardGroup,
		BCardBody,
		vSelect,
	},
	directives: {
		Ripple,
	},
	data() {
		const now = new Date()
		const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
		const minDate = new Date(today)
		return {
			version_resa: 'V1.0.0',
			bsoc: false,
			CRS: '',
			CSIRET: '',
			CTVA: '',
			CNOM: '',
			CPRENOM: '',
			CADRESSE: '',
			CCP: '',
			CVILLE: '',
			CPORT: '',
			CEMAIL: '',
			CCIVILITE: '',
			CCODE: '',
			bcode: false,
			bcodeok: false,
			MMESS: '',
			etapeencour: 1,
			maxetape: 3,
			min: minDate,
			max: minDate,
			taille: [],
			soustaille: [],
			soustaille2: [],
			soustaillearif1: [],
			soustaillearif2: [],
			soustaillearif3: [],
			tarifshoisi: [],
			soustaillechoixfinal: [],
			libsoc: '',
			libtaille: '',
			memotaille: '',
			libui: '',
			libsoustaille: '',
			libsoustailleui: '',
			C_assurance: '',
			selectedassurance: null,
			assurance: [],
			selectedpays: { name: 'Réunion', code: 'RE' },
			civilite: [{
					name: 'NC',
					id: '1',
				},
				{
					name: 'Monsieur',
					id: '4',
				},
				{
					name: 'Madame',
					id: '2',
				},
				{
					name: 'Mademoiselle',
					id: '3',
				},
			],
			selectcivilite: { name: 'NC', id: '1' },
		}
	},
	created() {
		const UISOC = this.UI
		this.bcodeok = false
		this.$http.get(`${this.APIURL}resa/gettaille/${UISOC}`)
		.then(response => {
			if (response.data.res === 1) {
				this.taille = response.data.ResultTailleBox
				this.libsoc = response.data.libelle
				const numberOfDaysToAdd = 15
				const now = new Date()
				const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
				const maxDate = new Date(today)
				// maxDate.setMonth(maxDate.getMonth() + 2)
				maxDate.setDate(maxDate.getDate() + numberOfDaysToAdd)
				this.max = maxDate
			} else {
				this.$toast({
					component: ToastificationContent,
					props: {
						title: 'Oups',
						icon: 'SmileIcon',
						variant: 'primary',
						text: 'Erreur : demande non trouvée',
					},
				},
				{
					timeout: 4000,
					position: 'top-center',
				})
			}
		}) 
	},
	setup() {
		const { router } = useRouter()	
		const UI = router.currentRoute.params.id
		const APIURL = 'https://api.reunibox.com/'
		return {
			UI,
			APIURL,
		}
	},
	methods: {
		selecttaille: function (taillechoisie) {   
			this.libtaille = taillechoisie.libelle
			this.memotaille = taillechoisie.memo2
			this.libui = taillechoisie.UI
			this.$http.get(`${this.APIURL}resa/getsoustaille/${taillechoisie.UI}`)
			.then(response => {
				if (response.data.res === 1) {
				this.soustaille = response.data.ResultSousTailleBox
				this.etapeencour = 2
			} else {
				this.$toast({
				component: ToastificationContent,
				props: {
					title: 'Oups',
					icon: 'SmileIcon',
					variant: 'primary',
					text: 'Erreur : demande non trouvée',
				},
				},
				{
				timeout: 4000,
				position: 'top-center',
				})
			}
			}) 
		},
		selectsoustaille: function (taillechoisie) {   
			this.libsoustaille = taillechoisie.libelle
			this.libsoustailleui = taillechoisie.UI
			this.etapeencour = 3
			let isoustaille = 0
			this.soustaille2 = []
			this.soustaillearif1 = []
			this.soustaillearif2 = []
			this.soustaillearif3 = []
			for (isoustaille = 0; isoustaille < this.soustaille.length; isoustaille += 1) {
				if (this.soustaille[isoustaille].UI === this.libsoustailleui) {
					if (isoustaille > 0) {
						// this.soustaille2.push(this.soustaille[isoustaille - 1])	
						this.soustaille2.push({
							UI: this.soustaille[isoustaille - 1].UI,
							libelle: this.soustaille[isoustaille - 1].libelle,
							image: this.soustaille[isoustaille - 1].image,
							text: this.soustaille[isoustaille - 1].text,
							nbbox: this.soustaille[isoustaille - 1].nbbox,
							Resulttarif: this.soustaille[isoustaille - 1].Resulttarif,
							itype: 0,
						})
					}
					// this.soustaille2.push(this.soustaille[isoustaille])
					this.soustaille2.push({
						UI: this.soustaille[isoustaille].UI,
						libelle: this.soustaille[isoustaille].libelle,
						image: this.soustaille[isoustaille].image,
						text: this.soustaille[isoustaille].text,
						nbbox: this.soustaille[isoustaille].nbbox,
						Resulttarif: this.soustaille[isoustaille].Resulttarif,
						itype: 1,
					})
					if (isoustaille < (this.soustaille.length - 1)) {
						// this.soustaille2.push(this.soustaille[isoustaille + 1])	
						this.soustaille2.push({
							UI: this.soustaille[isoustaille + 1].UI,
							libelle: this.soustaille[isoustaille + 1].libelle,
							image: this.soustaille[isoustaille + 1].image,
							text: this.soustaille[isoustaille + 1].text,
							nbbox: this.soustaille[isoustaille + 1].nbbox,
							Resulttarif: this.soustaille[isoustaille + 1].Resulttarif,
							itype: 2,
						})
					}
				} 
			}
		},
		goetape4() {
			let berr = false
			// let UIASS = ''
			this.totalmensuel = 0 // parseFloat(this.I_prixTTC) + parseFloat(this.ttcassurance)
			if ((this.CPORT === '') || (this.CEMAIL === '')) {
				berr = true	
			}
			if (this.bsoc) {
				if (this.CRS === '') {
					berr = true	
				}
			}
			if (!berr) {
				// this.libdatedebut = this.datedebut.split('-').reverse().join('/')
				// this.etapeencour = 4	
				this.$http.post(`${this.APIURL}resa/adddevis`, { UISOC: this.UI, EMAIL: this.CEMAIL, PORT: this.CPORT, CNOM: this.CNOM, CPRENOM: this.CPRENOM, CADRESSE: this.CADRESSE, CCP: this.CCP, CVILLE: this.CVILLE, CPAYS: this.selectedpays.name, CPAYSCODE: this.selectedpays.code, BSOC: this.bsoc, CRS: this.CRS, CSIRET: this.CSIRET, CTVA: this.CTVA, taillechoisielibelle: this.libtaille, taillechoisieUI: this.libui, soustaillechoisielibelle: this.libsoustaille, soustaillechoisieUI: this.libsoustailleui, DATEDEBUT: this.datedebut, CCIV: this.selectcivilite.id, CCILLIB: this.selectcivilite.name })
					.then(response => {
						if (response.data.res === 1) {
							const url = new URL(`/resa2/${response.data.UIdemande}`, window.location.origin)
							window.location.href = url.toString()
							// this.$router.push({ name: `/resa2/${response.data.UIdemande}` })
							// this.etapeencour = 4	
						} else {
							this.$toast({
								component: ToastificationContent,
								props: {
									title: 'Oups',
									icon: 'SmileIcon',
									variant: 'primary',
									text: 'Erreur : Erreur de connexion du serveur',
								},
							},
							{
								timeout: 4000,
								position: 'top-center',
							})	
						}	
					}) 
			} else {
				this.$toast({
					component: ToastificationContent,
					props: {
						title: 'Oups',
						icon: 'SmileIcon',
						variant: 'primary',
						text: 'Erreur : Merci de saisir tout les champs',
					},
				},
				{
					timeout: 4000,
					position: 'top-center',
				})		
			}
		},	
		selecttarif: function (tarifchoisi, soustailleitem2) {
				let berr2 = false
				if (tarifchoisi === '') {
					berr2 = true	
				}
				if (!berr2) {
					this.tarifshoisi = tarifchoisi
					this.soustaillechoixfinal = soustailleitem2
					this.etapeencour = 5	
				} else {
					this.$toast({
						component: ToastificationContent,
						props: {
							title: 'Oups',
							icon: 'SmileIcon',
							variant: 'primary',
							text: 'Erreur : Merci de saisir tout les champs',
						},
					},
					{
						timeout: 4000,
						position: 'top-center',
					})		
				}
		},	
	},
}
</script>
<style lang="scss">
.bounce-enter-active {
  animation: bounce-in .5s;
}
.bounce-leave-active {
   opacity: 0;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.20);
  }
  100% {
    transform: scale(1);
  }
}
@import '@core/scss/vue/libs/vue-select.scss';
</style>
<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
